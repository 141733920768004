.animated-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  overflow: hidden;
  background: linear-gradient(to bottom right, #111827, #1e3a8a, #6b21a8);
}

.floating-light {
  position: absolute;
  width: var(--size);
  height: var(--size);
  left: var(--x);
  top: var(--y);
  border-radius: 50%;
  background: radial-gradient(
    circle at center,
    var(--color) 0%,
    rgba(255, 255, 255, 0.6) 70%,
    rgba(255, 255, 255, 0.3) 10%,
    rgba(255, 255, 255, 0) 100%
  );
  opacity: 0.5;
  filter: blur(5px);
  animation: float var(--duration) var(--delay) infinite ease-in-out;
}

@keyframes float {
  0%, 100% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(calc(150px * var(--direction-x, 1)), calc(150px * var(--direction-y, 1)));
  }
  50% {
    transform: translate(calc(75px * var(--direction-x, 1)), calc(-200px * var(--direction-y, 1)));
  }
  75% {
    transform: translate(calc(-150px * var(--direction-x, 1)), calc(75px * var(--direction-y, 1)));
  }
}

.floating-light::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 50%;
  --direction-x: calc(2 * random() - 1);
  --direction-y: calc(2 * random() - 1);
}