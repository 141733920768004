.chatbot-container {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  width: 100vw; 
}

.chatbot-content {
  position: relative;
  z-index: 1;
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(to bottom right, rgba(107, 33, 168, 0.8), rgba(30, 58, 138, 0.8), rgba(17, 24, 39, 0.8));
  color: white;
  display: flex;
  flex-direction: column;
}

.chatbot-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.2);
}

.language-toggle {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  height: min-content;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.language-toggle:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.chatbot-main {
  flex-grow: 1;
  display: flex;
  padding: 2rem;
  width: 90%;
  box-sizing: border-box;
  height: calc(100vh - 200px); 
  overflow: hidden;
}

.avatar-section {
  display: flex;
  flex-direction: column; 
  align-items: center; 
  flex: 0 0 200px;
  margin-right: 2rem;
}

.chat-section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: calc(100% - 220px); 
  margin-top: 8%;
  overflow: hidden;
  height: 100%;
}

.welcome-screen {
  text-align: center;
}

.start-chat-btn {
  background-color: #8B5CF6;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.start-chat-btn:hover {
  background-color: #7C3AED;
}

.chat-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.voice-chat-btn, .clear-chat-btn, .stop-avatar-btn {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  margin-right: 15px;
  transition: background-color 0.3s ease;
}

.voice-chat-btn:hover, .clear-chat-btn:hover, .stop-avatar-btn:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.voice-chat-btn.recording {
  background-color: #EF4444;
}

.voice-chat-btn span, .clear-chat-btn span, .stop-avatar-btn span {
  margin-left: 0.5rem;
}

.messages-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 100px); 
}

.message {
  max-width: 70%;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  margin-bottom: 0.5rem;
}

.user-message {
  align-self: flex-end;
  background-color: #4A5568; 
  color: white;
}

.bot-message {
  align-self: flex-start;
  background-color: #6D28D9; 
  color: white;
}

.message-input {
  display: flex;
  margin-top: 1rem;
}

.text-input {
  flex-grow: 1;
  padding: 0.5rem;
  border: none;
  border-radius: 0.25rem 0 0 0.25rem;
}

.send-btn {
  background-color: #8B5CF6;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0 0.25rem 0.25rem 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.send-btn:hover {
  background-color: #7C3AED;
}

.header-logo {
  height: 90px; 
  margin-right: 40px;
}

.avatar-controls {
  margin-top: 10px; 
  display: flex;
  gap: 10px; 
}

.voice-chat-btn,
.stop-avatar-btn {
  display: flex;
  align-items: center;
  gap: 5px; 
}

@media (max-width: 768px) {
  .chatbot-main {
    flex-direction: column;
    padding: 1rem;
  }

  .avatar-section {
    flex: 0 0 auto;
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .chat-section {
    width: 100%;
  }

  .messages-container {
    max-height: calc(100vh - 200px); 
  }
}